import { useState } from "react";
import { Spinner } from "@chakra-ui/react";
import {
  AboutCard,
  Category,
  Label,
  Value,
  GridLayout,
  AboutSectionTitle,
  AboutSectionParagraph,
  AboutCardMobileDivider,
} from "./styles";
import AddOrEditOrganizationLinksModal from "../../../Components/Modal/AddOrEditOrganizationLinksModal";
import { useOrganization } from "../../../hooks/useOrganization";
import AboutLinksCard from "./AboutLinksCard";
import AboutLinksEmptyState from "./AboutLinksEmptyState";
import { truncate } from "../../../utils/helper";

export const About = () => {
  const [linksModalVisible, setLinksModalVisible] = useState(false);

  const {
    currentOrganizationLoading,
    currentOrganization: organization,
    isCurrentUserAdmin,
  } = useOrganization();

  if (currentOrganizationLoading) {
    return <Spinner color="red" />;
  }

  const renderLinkCard = () => {
    const links = organization?.orgFields?.links;
    const orgHasLinks =
      !!organization?.orgFields?.links &&
      organization?.orgFields?.links.length > 0;

    if (orgHasLinks) {
      return (
        <AboutLinksCard
          links={links}
          isAdmin={isCurrentUserAdmin}
          onEdit={() => setLinksModalVisible(true)}
        />
      );
    } else {
      if (isCurrentUserAdmin) {
        return (
          <AboutLinksEmptyState
            onPrimaryButtonClicked={() => setLinksModalVisible(true)}
          />
        );
      }
      return undefined;
    }
  };

  return (
    <div>
      {renderLinkCard()}
      <AboutCardMobileDivider />
      <AboutCard>
        <GridLayout>
          {/* <Category>
            <Label>Admin </Label>
            <Value>
              <PersonWrapper>
                <div className="first-column">
                  <ProfileAvatar
                    src={admin?.image?.url}
                    name={`${admin?.name?.firstName} ${admin?.name?.lastName}`}
                  />
                </div>

                <div className="second-column">
                  <p className="name">
                    {admin?.name ? truncate(`${admin?.name?.firstName} ${admin?.name?.lastName}`) : ""}
                  </p>
                  <p className="caption">Admin</p>
                </div>
              </PersonWrapper>
            </Value>
          </Category>
          <Category>
            <Label>Email </Label>
            <Value>{admin?.email}</Value>
          </Category> */}
          <Category>
            <Label>Contact </Label>
            <Value>{organization?.phoneNumber}</Value>
          </Category>
          <Category>
            <Label>Address</Label>
            <Value>
              {organization?.location.state}, {organization?.location.city},{" "}
              {organization?.location.address1}
            </Value>
          </Category>
        </GridLayout>
      </AboutCard>
      <AboutCardMobileDivider />
      <AboutCard>
        <AboutSectionTitle>What we do</AboutSectionTitle>
        <AboutSectionTitle>Problems we are solving</AboutSectionTitle>
        <AboutSectionParagraph>{organization?.problem}</AboutSectionParagraph>
        <AboutSectionTitle>Mission</AboutSectionTitle>
        <AboutSectionParagraph>{organization?.mission}</AboutSectionParagraph>
        <AboutSectionTitle>Vision</AboutSectionTitle>
        <AboutSectionParagraph>{organization?.vision}</AboutSectionParagraph>
        {linksModalVisible && organization && (
          <AddOrEditOrganizationLinksModal
            isOpen={linksModalVisible}
            organization={organization}
            onClose={() => setLinksModalVisible(false)}
          />
        )}
      </AboutCard>
    </div>
  );
};
