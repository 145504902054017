import React, { useState } from "react";
import { Form, Formik } from "formik";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import * as Yup from "yup";
import toast from "react-hot-toast";
import {
  Box,
  InputLeftElement,
  InputRightElement,
  Text,
  Button,
} from "@chakra-ui/react";
import { MESSAGE, MinMaxMessage } from "../../Constants/Message";
import { Eye, Password } from "../../Assets/SvgIcons";
import { InputV2 } from "../../Reusable";
import { SignUpWrapper } from "../SignUp/Styles";
import { customConsole } from "../../utils/helper";
import * as Sentry from "@sentry/react";

const NewPasswordRequired = () => {
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  //@ts-ignore
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const validationSchema = Yup.object({
    password: Yup.string()
      .required(MESSAGE.INPUT_REQUIRED)
      .min(8, MinMaxMessage("min", "Password", "8"))
      .max(30, MinMaxMessage("max", "Password", "30")),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required(MESSAGE.INPUT_REQUIRED)
      .min(8, MinMaxMessage("min", "Confirm password", "8"))
      .max(30, MinMaxMessage("max", "Confirm password", "30")),
  });

  const completeNewPassword = async ({ password, tempPassword }) => {
    try {
      setLoading(true);
      const user = await Auth.signIn(location.state.email, tempPassword);
      await Auth.completeNewPassword(user, password);
      toast.success("You successfully changed your password. Now login");
      setLoading(false);
      navigate("/");
    } catch (err) {
      navigate("/");
      window.location.reload();
      Sentry.captureException(err);
      setLoading(false);
      customConsole("error", err);
      toast.error(err || MESSAGE.SYSTEM_ERROR);
    }
  };

  return (
    <SignUpWrapper>
      <div className="col-logo">
        <img
          src="/images/logo-2.png"
          alt="Measure"
          className="img-logo mx-auto"
        />
      </div>

      <div className="col-form">
        <div className="box-wrapper mx-auto" style={{ maxWidth: 410 }}>
          <img
            src="/images/logo-3.png"
            alt="Measure"
            className="img-logo mx-auto"
          />
          {/** @ts-ignore */}
          <Box mb="32px">
            <Text
              color="#000"
              lineHeight={17 / 14}
              margin="auto"
              className="text-center"
            >
              Please update your password.
            </Text>
          </Box>
        </div>

        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (data, handlers) => {
            handlers.setSubmitting(true);
            handlers.validateForm(data);
            await completeNewPassword(data);
            handlers.setSubmitting(false);
          }}
        >
          {(formik) => {
            return (
              <Form>
                <div className="box-wrapper mx-auto" style={{ maxWidth: 410 }}>
                  <Box mb="32px">
                    <InputV2
                      name="tempPassword"
                      placeholder="Temporary password"
                      inputLeftElement={
                        <InputLeftElement
                          pointerEvents="none"
                          children={<Password />}
                        />
                      }
                      inputRightElement={
                        <InputRightElement
                          children={<Eye />}
                          pointerEvents="initial"
                          className="cursor-pointer"
                          onClick={() =>
                            setShowPasswordConfirm(!showPasswordConfirm)
                          }
                        />
                      }
                      restInput={{
                        type: showPasswordConfirm ? "text" : "password",
                      }}
                    />
                  </Box>
                  <Box mb="24px">
                    <InputV2
                      name="password"
                      placeholder="Password"
                      inputLeftElement={
                        <InputLeftElement
                          pointerEvents="none"
                          children={<Password />}
                        />
                      }
                      inputRightElement={
                        <InputRightElement
                          children={<Eye />}
                          pointerEvents="initial"
                          className="cursor-pointer"
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      }
                      restInput={{ type: showPassword ? "text" : "password" }}
                    />
                  </Box>
                  <Box mb="32px">
                    <InputV2
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      inputLeftElement={
                        <InputLeftElement
                          pointerEvents="none"
                          children={<Password />}
                        />
                      }
                      inputRightElement={
                        <InputRightElement
                          children={<Eye />}
                          pointerEvents="initial"
                          className="cursor-pointer"
                          onClick={() =>
                            setShowPasswordConfirm(!showPasswordConfirm)
                          }
                        />
                      }
                      restInput={{
                        type: showPasswordConfirm ? "text" : "password",
                      }}
                    />
                  </Box>

                  <Box mb="16px">
                    <Button
                      type="submit"
                      colorScheme="primary"
                      isLoading={loading}
                      loadingText="Get reset code"
                      className="w-100"
                    >
                      Reset password
                    </Button>
                  </Box>
                  {/* @ts-ignore */}
                  <Text
                    fontSize={16}
                    fontWeight="semibold"
                    color="#000"
                    textAlign="center"
                    padding="6px 0"
                  >
                    Go back to{" "}
                    <Text
                      as={Link}
                      to="/"
                      fontSize={16}
                      fontWeight="semibold"
                      _hover={{ color: "primary.600" }}
                    >
                      Login?
                    </Text>
                  </Text>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </SignUpWrapper>
  );
};
export default NewPasswordRequired;
