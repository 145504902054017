import { Field } from 'formik';
import { FormControl, FormLabel, FormErrorMessage, Checkbox } from '@chakra-ui/react';

export const CheckboxV2 = ({ name, title, children, restCheckbox, ...rest }: any) => {
  return (
    <Field name={name} {...rest}>
      {({ field, form }) => (
        <FormControl isInvalid={form.errors[name] && form.touched[name]}>
          {title && <FormLabel htmlFor={name}>{title}</FormLabel>}

          <Checkbox {...field} id={name} colorScheme="primary" {...restCheckbox}>
            {children}
          </Checkbox>

          <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};
