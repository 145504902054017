import React from 'react';
import { useMatch, useResolvedPath } from 'react-router-dom';

import { NavLink, TextBadge } from './Styles';

interface sidebarMenuProp {
  icon: any;
  option: string;
  to: string;
  textBadge?: string;
  dataCypressSelector?: string;
}

const SidebarMenu = ({ icon, option, to, textBadge, dataCypressSelector }: sidebarMenuProp) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <NavLink to={to} className={`${match ? 'active' : ''}`}>
      <div className="icon">{icon}</div>
      <div className="txt">
        <p data-cy={dataCypressSelector}>{option}</p>
      </div>
      {textBadge ? <TextBadge className={'badge-text'}>{textBadge}</TextBadge> : null}
    </NavLink>
  );
};

export default SidebarMenu;
