import { useMemo } from "react";
import { Menu, MenuButton, MenuList } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import useScreenType from "react-screentype-hook";
import queryString from "query-string";

import ProfileMenu from "./ProfileMenu";
import { Bell, HamburgerIcon } from "../../Assets/SvgIcons";
import { AddMemberIcon } from "../../Assets/SvgIcons";
import { AddMemberButton, AddMemberText, TextBadgeContainer } from "./Styles";

import { TextBadge } from "../Sidebar/Styles";
import { useGetNotificationsQuery,} from "../../../redux/reducers/apiSlice";
import { Notification } from "./Notification";

const HeaderRight = ({ toggleSidebar }) => {
  const location = useLocation();
  const { isMobile } = useScreenType();

  const navigate = useNavigate();
  const { data: notificationsData, refetch } = useGetNotificationsQuery(undefined, { pollingInterval: 1000 });
  const newNotifications = useMemo(() => {
    return notificationsData?.filter(
      (notification) => notification.status === "NEW"
    );
  }, [notificationsData]);

  const onAddMemberClicked = () => {
    const parsedQueryStrings = queryString.parse(location.search);
    parsedQueryStrings["inviteModalOpen"] = "true";
    const hasQuestionMark =
      Object.keys(parsedQueryStrings).length > 0 ? "?" : "";

    navigate(
      `${location.pathname}${hasQuestionMark}${queryString.stringify(
        parsedQueryStrings
      )}`
    );
  };

  if (
    isMobile &&
    location.pathname.includes("/organizations") &&
    location.pathname !== "/organizations/new" &&
    !location.pathname.includes("profile")
  ) {
    return (
      <AddMemberButton onClick={() => onAddMemberClicked()}>
        <AddMemberIcon />
        <AddMemberText>Invite member</AddMemberText>
      </AddMemberButton>
    );
  }

  return (
    <div className="profile-container">
      <div className="box-notification">
        <Menu placement="bottom-end">
          <MenuButton
            onClick={() => {}}
            disabled={
              !notificationsData?.length || notificationsData?.length <= 0
            }
          >
            <Bell
              color={
                notificationsData?.length && notificationsData.length > 0
                  ? "#000"
                  : "#aaa"
              }
              className="bell"
            />

            <TextBadgeContainer
              isVisible={
                newNotifications?.length && newNotifications.length > 0
              }
            >
              <TextBadge>{newNotifications?.length}</TextBadge>
            </TextBadgeContainer>
          </MenuButton>
          {newNotifications?.length && newNotifications.length > 0 ? (
            <MenuList>
              <div
                style={{
                  overflow: "scroll",
                  maxHeight: isMobile ? "80vh" : "20vh",
                }}
              >
                {notificationsData &&
                  notificationsData.map((notification, id) => {
                    return (
                      <div key={notification.id}>
                        <Notification notification={notification} />
                        {id !== notificationsData.length - 1 && <hr />}
                      </div>
                    );
                  })}
              </div>
            </MenuList>
          ) : null}
        </Menu>
      </div>

      <div className="profile-menu">
        <ProfileMenu />
      </div>
      <div className="hamburger-menu">
        <button onClick={() => toggleSidebar()}>
          <HamburgerIcon boxSize={25} />
        </button>
      </div>
    </div>
  );
};

export default HeaderRight;
