import { useEffect, useState } from "react";
import { formatDistanceToNow } from "date-fns";
import { useNavigate } from "react-router-dom";
import { ArrowForwardIcon, DeleteIcon } from "@chakra-ui/icons";
import toast from "react-hot-toast";

import {
  HeartReact,
  SnapReact,
  FistReact,
  ClappingReact,
} from "../../Assets/SvgIcons";

import { generateNotificationMessage } from "../../utils/helper";
import {  useLazyGetOrganizationDetailsQuery, useDeleteNotificationsMutation } from "../../../redux/reducers/apiSlice";

import {
  MenuItem,
  IconContainer,
  ReactionContainer,
} from "./NotificationStyles";


import { Link } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import AcceptInviteModal from "../../Components/Modal/AcceptInviteModal";

const keywordToSVG = {
  HEART: <HeartReact />,
  SNAPS: <SnapReact />,
  HIGHFIVE: <FistReact />,
  CLAP: <ClappingReact />,
  FIST: <FistReact />,
};

export const Notification = ({
  notification,
}) => {
  const navigate = useNavigate();
  const [getOrganizationDetails, { data: orgData, isLoading: orgNameLoading }] = useLazyGetOrganizationDetailsQuery();
  const [deleteNotifications] = useDeleteNotificationsMutation();
  const [isOpenAcceptInviteModal, setIsOpenAcceptInviteModal] = useState(false);

  useEffect(() => {

    const getOrgName = async () => {
      if(notification?.orgId){
          getOrganizationDetails(notification.orgId)
      }
    }

    getOrgName();
  },[])

  return (
    <MenuItem
      style={{
        background:
          notification.status === "VIEWED"
            ? "transparent"
            : "rgba(0,0,255,0.05)",
      }}
    >
      <div className="menu-wrapper">
        <div className="time-ago">
          {notification?.createdAt &&
            formatDistanceToNow(new Date(notification.createdAt), {
              addSuffix: true,
            }).replace("about", "")}
        </div>
        <div className="row">
          <div className="from">
            <span>{notification.from}</span>
          </div>
          <div className="message">
            {notification.action === "ORG_INVITE_ACCEPTED" && `just accepted your invitation`}
            {notification.action !== "ORG_INVITE" && notification.action !== "ORG_INVITE_ACCEPTED" && !notification?.orgId && generateNotificationMessage(
              notification.action,
              notification.postId,
              notification.interactionId
            )}
            {notification?.orgId && orgData && (
              <>
              {notification.action === 'ORG_INVITE' ? `invited you to join an organization named: `: `to join an organization named: `}
              <Link to={`/organizations/${notification?.orgId}/profile`}>
                {orgData.name}
              </Link>
              </>
            )}
            <ReactionContainer>
              {keywordToSVG[notification.action]}
            </ReactionContainer>
          </div>
          <div className="icons">
          {notification?.action === "ORG_INVITE" && notification.action !== "ORG_INVITE_ACCEPTED" && (
              <Button onClick={() => setIsOpenAcceptInviteModal(true)} style={{backgroundColor: 'transparent', color: 'rgb(238,90,41)'}}>
                View invitation
              </Button>
            )}
            {notification?.action === "ORG_INVITE_ACCEPTED" && (
              <IconContainer>
                <button style={{paddingLeft: 6}}
                  onClick={async () => {
                      try {
                        await deleteNotifications({ id: notification.id });
                        toast("Notification deleted");
                      } catch(e){
                        toast.error("Something unexpected happened while deleting your notification");
                      }
                  }}
                >
                  <DeleteIcon />
                </button>
              </IconContainer>
            )}
            {notification?.action !== "ORG_INVITE" && notification.action !== "ORG_INVITE_ACCEPTED" && (
              <IconContainer>
                <button style={{paddingLeft: 6}}
                  onClick={async () => {
                      if (notification.postId) {
                        if (
                          notification.interactionId &&
                          (notification.action === "COMMENT" ||
                            notification.action === "TAG")
                        ) {
                          navigate(
                            `/posts/${notification.postId}/interactions/${notification.interactionId}?showComment=true`
                          );
                        } else {
                          navigate(`/posts/${notification.postId}`);
                        }
                      } else if (notification.userId) {
                        navigate(`/users/${notification.userId}`);
                      }
                      try {
                        await deleteNotifications({ id: notification.id });
                        toast("Notification deleted");
                      } catch(e){
                        toast.error("Something unexpected happened while deleting your notification");
                      }
                  }}
                >
                  <ArrowForwardIcon />
                </button>
              </IconContainer>
            )}
          </div>
        </div>
      </div>
      {orgData?.name && isOpenAcceptInviteModal && 
      <AcceptInviteModal
        isOpen={isOpenAcceptInviteModal}
        onClose={() => setIsOpenAcceptInviteModal(false)}
        organizationName={orgData?.name}
        notification={notification}
      />}
    </MenuItem>
  );
};
