import React, { useState } from "react";
import toast from "react-hot-toast";
import {
  TargetIcon,
  VerticalMoreIcon,
  PlusRoundCircle,
} from "../../Assets/SvgIcons";
import Metric from "../../Components/Metric/Metric";
import { GoalCardWrapper, FloatingDropdownWrapper } from "./styles";
import { FloatingDropdown } from "../../Components/FloatingDropdown/FloatingDropdown";
import { useDeleteOrganizationGoalMutation } from "../../../redux/reducers/apiSlice";
import { Modal } from "../../Components/Modal/Modal";
import CreateOrEditModal from "../../Components/Modal/CreateOrEditMetricModal";
import { Metric as MetricInterface } from "../../../redux/reducers/types";
import { useOrganization } from "../../hooks/useOrganization";
import * as Sentry from "@sentry/react";

interface Props {
  goal: {
    name: string;
    description: string;
    id: string;
    metrics?: MetricInterface[];
  };
  sparkId: number | undefined;
  isAdmin: boolean;
  organizationId: string;
  onEdit: () => void;
}

export const GoalCard = ({
  goal,
  organizationId,
  isAdmin,
  sparkId,
  onEdit,
}: Props) => {
  const [metricToEdit, setMetricToEdit] = useState<MetricInterface | undefined>(
    undefined
  );
  const [floatingDropdownVisible, setFloatingDropdownVisible] = useState(false);
  const [createMetricModalVisible, setCreateMetricModalVisible] =
    useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteGoal, { isLoading: deleteOrganizationGoalLoading }] =
    useDeleteOrganizationGoalMutation();

  const { isCurrentUserAdmin } = useOrganization();

  let adminDropdownItems = isAdmin
    ? [
        {
          label: "Edit goal",
          action: () => onEdit(),
        },
        {
          label: "Delete goal",
          isDestructiveAction: true,
          isLast: true,
          action: () => setDeleteModalVisible(true),
        },
      ]
    : [];

  const onDeleteGoal = async () => {
    try {
      await deleteGoal({
        orgId: organizationId,
        goalId: goal.id,
      }).unwrap();
    } catch (e) {
      Sentry.captureException(e);
      toast.error(
        "Something unexpected happen while trying to delete your goal. Try again later"
      );
    }
  };

  return (
    <GoalCardWrapper>
      <div className="top-row">
        <span className="title-and-icon">
          <TargetIcon />
          <p className="title">{goal.name}</p>
        </span>
        <FloatingDropdownWrapper>
          <FloatingDropdown
            visible={floatingDropdownVisible}
            onOutsideClicked={() => {
              setFloatingDropdownVisible(false);
            }}
            items={[
              {
                label: "Export",
                action: () => {},
              },
              ...adminDropdownItems,
            ]}
          />
        </FloatingDropdownWrapper>
        <div>
          <button
            className="options-button"
            onClick={() => setFloatingDropdownVisible(true)}
          >
            <VerticalMoreIcon color="black" />
          </button>
        </div>
      </div>
      <div>
        <p className="description">{goal.description}</p>
      </div>
      {goal.metrics?.map((metric, idMetric) => (
        <Metric
          goalId={goal.id}
          orgId={organizationId}
          key={idMetric}
          metric={metric}
          onEdit={() => {
            setMetricToEdit(metric);
            setCreateMetricModalVisible(true);
          }}
          isAdmin={isCurrentUserAdmin}
        />
      ))}
      {isCurrentUserAdmin && (
        <button
          onClick={() => {
            setMetricToEdit(undefined);
            setCreateMetricModalVisible(true);
          }}
          className="metric-button"
        >
          <PlusRoundCircle color={"black"} />
          <span className="metric-button-text">Add metric</span>
        </button>
      )}
      {createMetricModalVisible && (
        <CreateOrEditModal
          isOpen={createMetricModalVisible}
          onClose={() => setCreateMetricModalVisible(false)}
          goalId={goal.id}
          sparkId={sparkId}
          orgId={organizationId}
          metric={metricToEdit}
        />
      )}
      {deleteModalVisible && (
        <Modal
          isOpen={deleteModalVisible}
          onClose={() => setDeleteModalVisible(false)}
          primaryButtonText={`Delete goal`}
          secondaryButtonText={"Cancel"}
          modalTitle={"Are you sure you want to delete goal?"}
          onPrimaryButtonClicked={() => onDeleteGoal()}
          primaryLoading={deleteOrganizationGoalLoading}
          disablePrimaryButton={deleteOrganizationGoalLoading}
        />
      )}
    </GoalCardWrapper>
  );
};
