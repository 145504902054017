import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Divider } from "@chakra-ui/react";
import { PostCard } from "../PostCard/PostCard";
import { Modal } from "./Modal";
import { InputV2 } from "../../Reusable";
import { Formik } from "formik";
import * as yup from "yup";
import { Props } from "./types";
import {
  useCreatePostMutation,
  useLazyGetProfileQuery,
} from "../../../redux/reducers/apiSlice";
import { Post, Metric as MetricInterface } from "../../../redux/reducers/types";
import Metric from "../Metric/Metric";
import { useLocation } from "react-router-dom";
import {
  ChevronDownIconContainer,
  Name,
  UserOrOrganizationInfo,
  UserOrOrganizationWrapper,
} from "./Styles";
import { ProfileAvatar } from "../ProfileAvatar/ProfileAvatar";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import FullPageNexusLogoAnimation from "../../Reusable/FullPageNexusLogoAnimation/FullPageNexusLogoAnimation";
import { customConsole } from "../../utils/helper";
import { MESSAGE } from "../../Constants/Message";
import useScreenType from "react-screentype-hook";
import { truncate } from "../../utils/helper";

import * as Sentry from "@sentry/react";

const validationSchema = yup.object({
  caption: yup.string(),
});

const ShareModal = ({ type, entity, isOpen, onClose }: Props) => {
  const [createPost, { isLoading: createPostLoading }] =
    useCreatePostMutation();
  const [getProfile, { isLoading: profileDataIsLoading, data: profileData }] =
    useLazyGetProfileQuery();
  const location = useLocation();
  const { isMobile } = useScreenType();
  const urlHasOrganization = location.pathname.includes("organization");
  const [shareEntity, setShareEntity] = useState({
    name: "",
    image: "",
    orgId: "",
    isOrg: false,
  });

  useEffect(() => {
    fetchProfileData();
    setShareEntity({
      name: `${profileData?.name.firstName} ${profileData?.name?.lastName}`,
      image: profileData?.image?.url,
      orgId: "",
      isOrg: false,
    });
  }, [profileData]);

  const fetchProfileData = async () => {
    try {
      await getProfile().unwrap();
    } catch (error) {
      Sentry.captureException(error);
      customConsole("error", "Error", error);
      toast.error(MESSAGE.SYSTEM_ERROR);
    }
  };

  if (profileDataIsLoading) {
    return <FullPageNexusLogoAnimation />;
  }

  const organizations = profileData?.organizations || [];
  const myOrganizations = organizations.filter((org) => org.myRole === "ADMIN");

  const handleSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("caption", data.caption);
      formData.append("type", type === "METRIC" ? "METRIC" : "SHARE");
      formData.append(
        type === "METRIC" ? "sharedMetricId" : "sharedPostId",
        entity.id
      );
      formData.append(
        "ownerId",
        // @ts-ignore
        shareEntity.isOrg ? shareEntity.orgId : profileData?.id
      );
      formData.append("ownerType", shareEntity.isOrg ? "ORGANIZATION" : "USER");

      await createPost(formData).unwrap();
      onClose();
      window.scrollTo({ top: 0, behavior: "smooth" });

      const entityName =
        type === "POST" ? (entity as Post)?.ownerName : shareEntity.name;
      toast.success(
        `You just shared a ${
          type === "METRIC" ? "metric" : "post"
        } by: ${truncate(entityName)}`
      );
    } catch (error) {
      Sentry.captureException(error);
      customConsole("PostSubmitError", error);
      toast.error(
        "Something went wrong while creating your post. Try again later"
      );
    }
  };

  return (
    <Formik
      initialValues={{ caption: "" }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(formik) => (
        <Modal
          cypressModalHeaderSelector="cypress-share-post-modal-header"
          isOpen={isOpen}
          onClose={onClose}
          modalTitle={`Share ${urlHasOrganization ? "metric" : "post"}`}
          secondaryButtonText="Cancel"
          primaryButtonText="Share"
          primaryLoading={createPostLoading}
          disablePrimaryButton={createPostLoading}
          onPrimaryButtonClicked={formik.handleSubmit}
        >
          <div style={{ padding: "24px" }}>
            <UserOrOrganizationWrapper>
              {myOrganizations.length > 0 && (
                <Menu>
                  <MenuButton>
                    <UserOrOrganizationInfo>
                      <ProfileAvatar src={shareEntity.image} />
                      <div
                        style={{
                          maxWidth: "50%",
                          paddingLeft: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        {shareEntity.isOrg
                          ? truncate(shareEntity.name, 127)
                          : truncate(shareEntity.name)}
                      </div>
                      <ChevronDownIconContainer>
                        <ChevronDownIcon />
                      </ChevronDownIconContainer>
                    </UserOrOrganizationInfo>
                  </MenuButton>

                  <MenuList>
                    <MenuItem
                      onClick={() =>
                        setShareEntity({
                          name: `${profileData?.name.firstName} ${profileData?.name?.lastName}`,
                          image: profileData?.image?.url,
                          orgId: "",
                          isOrg: false,
                        })
                      }
                    >
                      <Name>{`${profileData?.name.firstName} ${profileData?.name?.lastName}`}</Name>
                    </MenuItem>
                    {myOrganizations.map((org) => (
                      <MenuItem
                        key={org.id}
                        onClick={() =>
                          setShareEntity({
                            name: org.name,
                            image: org.image.url,
                            orgId: org.id,
                            isOrg: true,
                          })
                        }
                      >
                        <Name>{org.name}</Name>
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              )}
            </UserOrOrganizationWrapper>
            <div style={{ marginBottom: "24px" }}>
              <InputV2
                formik={formik}
                name="caption"
                value={formik.values.caption}
                placeholder="Write a caption for your shared post"
              />
            </div>
            <Divider />
            {type === "POST" && (
              <div style={{ marginTop: "24px" }}>
                <PostCard post={entity as Post} sharedPost />
              </div>
            )}
            {type === "METRIC" && (
              <div style={{ marginTop: "24px" }}>
                <Metric isShared={true} metric={entity as MetricInterface} />
              </div>
            )}
          </div>
        </Modal>
      )}
    </Formik>
  );
};

export default ShareModal;
