import { Modal } from "./Modal";
import { Button } from "@chakra-ui/react";
import { useAcceptInviteMutation, useDeleteNotificationsMutation, useGetProfileQuery } from "../../../redux/reducers/apiSlice";
import { toast } from "react-hot-toast";
import styled from "styled-components";
import { devices } from "../../Theme.css";

const AcceptInviteModal = ({ isOpen, onClose, organizationName, notification }) => {

  const { data: profile } = useGetProfileQuery();
  const [acceptInvite, { isLoading: isLoadingAcceptInvite } ] = useAcceptInviteMutation();
  const [deleteNotifications, { isLoading: isLoadingDeleteNotification }] = useDeleteNotificationsMutation();

  const handleAcceptInvite = async () => {
    try {
        if(profile?.id){
          await acceptInvite({ orgId: notification.orgId, inviterId: notification.userId }).unwrap();
          await deleteNotifications({ id: notification.id });
          onClose();
          toast.success("Invitation accepted");
        }
      } catch(e){
        onClose();
        toast.error("Something unexpected happened");
      }
  }
    
  const handleDeclineInvite = async () => {
    try {
      await acceptInvite({ orgId: notification.orgId, inviterId: notification.userId, accept: false }).unwrap();
      await deleteNotifications({ id: notification.id });
      toast.success("Notification deleted");
    } catch(e){
      toast.error("Something unexpected happened");
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      modalTitle={`Do you accept joining ${organizationName}?`}
    >
      <ButtonContainerStyles>
        <Button disabled={isLoadingAcceptInvite} className="primary-button" onClick={handleAcceptInvite}>
          Yes, I want to join
        </Button>
        <Button disabled={isLoadingDeleteNotification} className="secondary-button" onClick={handleDeclineInvite}>
          No, I don't want to join
        </Button>
      </ButtonContainerStyles>
    </Modal>
  );
};

export default AcceptInviteModal;

const ButtonContainerStyles = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 24px;

  .primary-button {
    background: rgb(238,90,41);
    color: white;
  }

  .secondary-button {
    background:#EDF2F7;
  }

  @media ${devices.max_sm}{
    flex-direction: column;

    .primary-button, .secondary-button {
      width: 100%;
      margin-top: 12px;
    }
  }
`