import React from "react";
import { Field } from "formik";
import {
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import {
  getNestedFormikValue,
  isNestedFormikValuePresent,
} from "../../utils/formErrors";

export const InputV2 = ({
  name,
  title,
  placeholder,
  inputLeftElement,
  inputRightElement,
  onKeyUp,
  onFocus,
  restInput,
  value,
  cypressInputSelector,
  cypressErrorSelector,
  ...rest
}: any) => {
  return (
    <Field name={name} {...rest} onKeyUp={onKeyUp} onFocus={onFocus}>
      {({ field, form }) => {
        return (
          // @ts-ignore
          <FormControl
            isInvalid={
              isNestedFormikValuePresent(form.errors, name) &&
              isNestedFormikValuePresent(form.touched, name)
            }
            aria-invalid={
              isNestedFormikValuePresent(form.errors, name) &&
              isNestedFormikValuePresent(form.touched, name)
            }
          >
            {title && <FormLabel htmlFor={name}>{title}</FormLabel>}

            <InputGroup>
              {inputLeftElement && (
                <InputLeftElement>{inputLeftElement}</InputLeftElement>
              )}
              <Input
                data-cy={cypressInputSelector}
                {...field}
                id={name}
                placeholder={placeholder || ""}
                value={value}
                {...restInput}
              />
              {inputRightElement && (
                <InputRightElement>{inputRightElement}</InputRightElement>
              )}
            </InputGroup>
            <FormErrorMessage data-cy={cypressErrorSelector}>
              {getNestedFormikValue(form.errors, name)}
            </FormErrorMessage>
          </FormControl>
        );
      }}
    </Field>
  );
};