import { useState } from "react";
import { Modal } from "./Modal";
import { CloseIcon } from "@chakra-ui/icons";
import hotToast from "react-hot-toast";
import { useParams } from "react-router";
import { v4 as uuid } from "uuid";
import {
  InviteModalBody,
  InviteInput,
  InviteInputWrapper,
  EmailError,
  PillsContainer,
  Pills,
  Pill,
  Instructions,
} from "./Styles";
import {
  customConsole,
  removeDuplicates,
  removeKeysFromEmails,
} from "../../utils/helper";
import { MESSAGE } from "../../Constants/Message";
import { EmailPayload, EmailState } from "../../../types";
import {
  useGetProfileQuery,
  useInviteMemberIntoOrganizationMutation,
  useLazySearchForMemberQuery,
} from "../../../redux/reducers/apiSlice";
import * as Sentry from "@sentry/react";

const InviteMemberModal = ({ isOpen, onClose }) => {
  const [inviteInputValue, setInviteInputValue] = useState("");
  const [inputIsFocused, setInputIsFocused] = useState(false);

  const { data: currentUserProfile } = useGetProfileQuery();

  const [inviteMemberIntoOrganization, { isLoading: loadingInvite }] =
    useInviteMemberIntoOrganizationMutation();
  const [searchForMember, { isLoading: loadingNewMemberToInvite }] =
    useLazySearchForMemberQuery();
  const [emailsToSendInviteTo, setEmailsToSendInviteTo] = useState<
    [] | Array<EmailState>
  >([]);
  const [isEmail, setIsEmail] = useState<boolean | null>(null);
  const { organizationId } = useParams();

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && inputIsFocused && inviteInputValue && isEmail) {
      fetchNewMember();
    }
  };

  const updateEmailsToSendInviteTo = (email: EmailPayload) => {
    const emailsToSendInviteToCopy = [...emailsToSendInviteTo];
    const emailToAdd = { ...email, key: uuid() };
    emailsToSendInviteToCopy.push(emailToAdd);
    setEmailsToSendInviteTo(removeDuplicates(emailsToSendInviteToCopy));
    setInviteInputValue("");
  };

  const fetchNewMember = async () => {
    try {
      if (organizationId) {
        const response = await searchForMember({
          email: encodeURIComponent(inviteInputValue),
          orgId: organizationId,
        });

        // @ts-ignore
        if (response?.error?.status === 404) {
          updateEmailsToSendInviteTo({
            email: inviteInputValue,
            id: undefined,
          });
        }

        // @ts-ignore
        if (response?.error && response?.error?.status !== 404) {
          // @ts-ignore
          throw new Error(response.error?.data.message);
        }

        if (response?.data) {
          updateEmailsToSendInviteTo(response.data as EmailPayload);
        }
      }
    } catch (e) {
      Sentry.captureException(e);
      customConsole("log", "went here");
      customConsole("log", "error", e?.message);
      hotToast.error(e?.message || MESSAGE.SYSTEM_ERROR);
    }
  };

  const handleChange = (e) => {
    if (
      currentUserProfile?.email &&
      e.target.value === currentUserProfile?.email
    ) {
      hotToast.error("You cannot send an invite to yourself");
      setInviteInputValue("");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(e.target.value)) {
      setIsEmail(true);
    } else {
      setIsEmail(false);
    }
    setInviteInputValue(e.target.value);
  };

  const handlePrimaryButtonClicked = async () => {
    const data = removeKeysFromEmails(emailsToSendInviteTo);
    try {
      if (organizationId && data) {
        const result =await inviteMemberIntoOrganization({
          orgId: organizationId,
          emails: data,
        }).unwrap();

        //@ts-ignore
        hotToast.success(result.message || "Invite successfully sent");
        setEmailsToSendInviteTo([]);
      }
    } catch (e) {
      Sentry.captureException(e);
      hotToast.error(e?.response?.data?.message || MESSAGE.SYSTEM_ERROR);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      primaryButtonText={"Send invite"}
      secondaryButtonText={"Cancel"}
      modalTitle={"Invite member"}
      onPrimaryButtonClicked={handlePrimaryButtonClicked}
      primaryLoading={loadingInvite}
      disablePrimaryButton={emailsToSendInviteTo.length <= 0}
    >
      <InviteModalBody>
        <InviteInputWrapper>
          <InviteInput
            disabled={loadingNewMemberToInvite}
            onChange={handleChange}
            value={inviteInputValue}
            placeholder="Type in an email"
            type="email"
            onKeyDown={handleKeyPress}
            onFocus={() => setInputIsFocused(true)}
            onBlur={() => setInputIsFocused(false)}
          />
          {loadingNewMemberToInvite && <p>Searching...</p>}
          {isEmail !== null && !isEmail && (
            <EmailError>Should be an email</EmailError>
          )}
        </InviteInputWrapper>
        {isEmail && inviteInputValue !== "" && (
          <Instructions>
            Press <kbd>Enter</kbd> to add the email
          </Instructions>
        )}
        <PillsContainer>
          <Pills onClick={() => {}}>
            {emailsToSendInviteTo.map((email: EmailState) => (
              <Pill key={email.key}>
                {email.email}
                <button
                  style={{ marginLeft: "8px" }}
                  onClick={() => {
                    const emailsToInviteToCopy = emailsToSendInviteTo.filter(
                      (emailToSendInviteTo) =>
                        // @ts-ignore
                        emailToSendInviteTo.key !== email.key
                    );
                    setEmailsToSendInviteTo(emailsToInviteToCopy);
                  }}
                  key={email.key}
                >
                  <CloseIcon boxSize={2} />
                </button>
              </Pill>
            ))}
          </Pills>
        </PillsContainer>
      </InviteModalBody>
    </Modal>
  );
};

export default InviteMemberModal;
