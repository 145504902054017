import { useState, useRef, useEffect } from "react";
import toast from "react-hot-toast";
import { InputGroup, InputRightElement, Spinner } from "@chakra-ui/react";
import { SendIcon } from "../../Assets/SvgIcons";
import { useParams } from "react-router-dom";
import CustomMention from "../CustomMentions/CustomMentions";
import { SuggestionDataItem } from "../../../types";

import {
  CommentSpacer,
  CommentsAndInputWrapper,
  CommentsWrapper,
  CommentInputContainer,
  mentionStyle,
} from "./styles";

import { Mention, MentionsInput } from "react-mentions";

import {
  useAddInteractionsToPostMutation,
  useTagUserMutation,
} from "../../../redux/reducers/apiSlice";
import { getUsersFromTags } from "../../utils/helper";
import { Interaction } from "../../../redux/reducers/types";
import { Comment } from "../Comment/Comment";
import { useMentions } from "../../hooks/useMentions";
import * as Sentry from "@sentry/react";
interface Props {
  comments: Interaction[];
  visible: boolean;
  postId: string;
  fullHeight?: boolean;
}

export const Comments: React.FC<Props> = ({
  comments,
  visible,
  postId,
  fullHeight,
}) => {
  const { interactionId } = useParams();
  const [comment, setComment] = useState("");
  const commentsRef = useRef<HTMLDivElement>(null);
  const commentIdsRef = useRef<HTMLDivElement | {}>({});
  const [addComment, { isLoading: isAddCommentLoading }] =
    useAddInteractionsToPostMutation();
  const [tagUser, { isLoading: loadingTagUser }] = useTagUserMutation();

  const { mentionsData } = useMentions();

  const submitComment = async () => {
    try {
      const commentData = await addComment({
        type: "COMMENT",
        id: postId,
        data: {
          message: comment,
        },
      }).unwrap();

      await tagUser({
        targetUserIds: getUsersFromTags(comment).map((user) => user.id),
        postId: postId,
        interactionId: commentData.id,
      });

      setComment("");
      if (commentsRef?.current) {
        commentsRef.current.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    } catch (e) {
      Sentry.captureException(e);
      toast.error("Something went wrong while trying to add your comment");
    }
  };

  useEffect(() => {
    if (interactionId) {
      scrollToSpecificComment(interactionId);
    }
  }, [interactionId]);

  const scrollToSpecificComment = (commentId: string) => {
    if (commentIdsRef?.current && commentIdsRef?.current[commentId]) {
      commentIdsRef.current[commentId].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <CommentsAndInputWrapper show={visible}>
      <CommentInputContainer>
        <InputGroup style={{ zIndex: 10000 }}>
          <MentionsInput
            value={comment}
            onChange={(event) => setComment(event.target.value)}
            maxLength={200}
            placeholder="Enter your comment here"
            singleLine
            style={mentionStyle}
            data-cy="cypress-comment"
            // id="cypress-comment"
          >
            <Mention
              trigger="@"
              data={mentionsData}
              className="mention"
              renderSuggestion={(suggestionDataItem: SuggestionDataItem) => (
                <CustomMention suggestionDataItem={suggestionDataItem} />
              )}
            />
          </MentionsInput>
          <InputRightElement>
            <button disabled={isAddCommentLoading} onClick={submitComment} id="cypress-submit-comment-button">
              {isAddCommentLoading ? <Spinner color="red" /> : <SendIcon />}
            </button>
          </InputRightElement>
        </InputGroup>
      </CommentInputContainer>
      {comments.length > 0 && (
        <CommentsWrapper
          fullHeight={fullHeight}
          hasOneComment={comments.length <= 1}
          ref={commentsRef}
        >
          {comments.map((comment) => (
            <CommentSpacer key={comment.id}>
              <Comment
                ref={(el) => {
                  if (commentIdsRef.current) {
                    commentIdsRef.current[comment.id] = el;
                  }
                }}
                fromNotification={comment.id === interactionId}
                comment={comment}
              />
            </CommentSpacer>
          ))}
        </CommentsWrapper>
      )}
    </CommentsAndInputWrapper>
  );
};
