import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tabs } from "../../../Components/Tabs/Tabs";
import {
  OrganizationProfileWrapper,
  OrganizationProfileTopCard,
  OrganizationProfilePicture,
  OrganizationCarePictureContainer,
  SecondaryButton,
  OrganizationSparks,
  SquareCard,
  OrganizationMobileTopCard,
  OrganizationMobileTopCardTopLayout,
  OrganizationMobileName,
  OrganizationMobileType,
  OrganizationMobileFollowIngCount,
  OrganizationMobileFollowerCount,
  OrganizationMobileGoals,
  OrganizationMobileSecondaryButton,
  BackButtonWrapper,
  FollowButtonSpacer,
  MobileButtonLayout,
  Header, 
  QRCodeWrapper,
  Message, 
  PageWrapper,
} from "./styles";
import OrganizationEditModal from "../../../Components/Modal/OrganizationEditModal";
import { PencilWithoutSquare } from "../../../Assets/SvgIcons";
import FollowButton from "../../../Pages/Profile/FollowButton";
import { About } from "../../../Components/Tabs/About/About";
import { Sparks } from "../../../Components/Tabs/Sparks/Sparks";
import { Back } from "../../../Components/Back/Back";
import { useOrganization } from "../../../hooks/useOrganization";
//@ts-ignore
import careCertifiedImage from "/images/care-certified.png";
import FullPageNexusLogoAnimation from "../../../Reusable/FullPageNexusLogoAnimation/FullPageNexusLogoAnimation";
import { ProfileAvatar } from "../../../Components/ProfileAvatar/ProfileAvatar";
import { useSpark } from "../../../hooks/useSpark";
import { Posts } from "../../../Components/Tabs/Posts/Posts";
import ShareQRCodeModal from "../../../Components/Modal/ShareQrCodeModal";
import { QRCodeIcon } from "../../../Assets/SvgIcons";
import AntiRacistStatementModal from "../../../Components/Modal/AntiRacistStatementModal";
import { truncate } from "../../../utils/helper";
import { useSelector } from "react-redux";
import { tokenSelector } from "../../../../redux/reducers/authSlice";
import { QRCode } from "../../../Components/Modal/Styles";
import { Link } from "react-router-dom";

const OrganizationProfile = () => {
  const [editSparkModalVisible, setEditSparkModalVisible] = useState(false);
  const navigate = useNavigate();
  const [shareQrCodeModalVisible, setShareQrCodeModalVisible] = useState(false);
  const [showAntiRacistModal, setShowAntiRacistModal] = useState(false);
  const { getSpark, sparksLoading } = useSpark();

  const {
    organizationPosts,
    organizationPostsLoading,
    currentOrganization,
    currentOrganizationLoading,
    isCurrentUserAdmin
  } = useOrganization();

  const isAuthenticated = useSelector(tokenSelector)

  const tabs = [
    {
      label: "Home",
      component: (
        <Posts
          showPostCreationForm={
            currentOrganization && isCurrentUserAdmin
          }
          posts={organizationPosts}
          postsLoading={organizationPostsLoading}
          withSuggestions={false}
          hideLoadMore
        />
      ),
    },
    { label: "About", component: <About /> },
    {
      label: "Goals",
      component: (
        <Sparks
          orgId={currentOrganization?.id}
          sparks={currentOrganization?.sparks}
          goals={currentOrganization?.goals}
        />
      ),
    },
  ];

  if (currentOrganizationLoading || sparksLoading) {
    return <FullPageNexusLogoAnimation />;
  }

  return (
    <PageWrapper isAuthenticated={isAuthenticated}>
        
        {!isAuthenticated && (
          <Header>
            <Message>You accessed this profile through this QR code. To access Measure Ignite, please <Link style={{fontWeight: "bold"}} to="/">connect</Link> or <Link style={{fontWeight: "bold"}} to="/signup">create an account</Link></Message>
             <QRCodeWrapper>
                <div style={{ width: 50, height: 50}}>
                  <QRCode
                    value={window.location.href}
                  />
                </div>
             </QRCodeWrapper>
          </Header>
        )}

    <OrganizationProfileWrapper>
      <BackButtonWrapper>
        <Back onClick={!isAuthenticated ? () => navigate("/") : () => navigate(-1)} />
      </BackButtonWrapper>
      <OrganizationMobileTopCard>
        <OrganizationMobileTopCardTopLayout>
          <ProfileAvatar
            name={currentOrganization?.name}
            src={currentOrganization?.image?.url}
          />
          <div className="second-column">
            <OrganizationMobileGoals>
              {currentOrganization?.sparks?.map((spark, id) => (
                <div key={id} className="icon-container">
                  <img src={getSpark(spark)?.logo} />
                </div>
              ))}
            </OrganizationMobileGoals>
            <div className="followers-info">
              <OrganizationMobileFollowIngCount>
                <p>Following</p>
                <p>{currentOrganization?.followingCount}</p>
              </OrganizationMobileFollowIngCount>
              <OrganizationMobileFollowerCount>
                <p>Followers</p>
                <p>{currentOrganization?.followerCount}</p>
              </OrganizationMobileFollowerCount>
            </div>
          </div>
        </OrganizationMobileTopCardTopLayout>
        <OrganizationMobileName>
          {currentOrganization?.name ? truncate(currentOrganization?.name) : ""}
        </OrganizationMobileName>
        <OrganizationMobileType>
          {currentOrganization?.type.toUpperCase()}
        </OrganizationMobileType>
        <MobileButtonLayout>
          <OrganizationMobileSecondaryButton
            onClick={() => setShareQrCodeModalVisible(true)}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {currentOrganization?.id && (
                <div style={{ marginRight: "8px" }}>
                  <QRCodeIcon />
                </div>
              )}
              <span style={{ display: "block" }}>Share</span>
            </div>
          </OrganizationMobileSecondaryButton>
          {isCurrentUserAdmin ? (
            <OrganizationMobileSecondaryButton
              onClick={() =>
                navigate(`/organizations/${currentOrganization?.id}/edit`)
              }
            >
              Edit Organization
            </OrganizationMobileSecondaryButton>
          ) : (
            <FollowButtonSpacer>
              {currentOrganization?.id && (
                <FollowButton
                  userId={currentOrganization?.id}
                  accountType="ORG"
                />
              )}
            </FollowButtonSpacer>
          )}
        </MobileButtonLayout>
      </OrganizationMobileTopCard>
      <OrganizationProfileTopCard introCardPresent={true}>
        <div className="top-row">
          <div className="row-group">
            <h1>
              {currentOrganization?.name && truncate(currentOrganization?.name)}
            </h1>
            <h2>{currentOrganization?.type.toUpperCase()}</h2>
          </div>
          <div className="row-group-2">
            <div>
              <p className="label">Followers</p>
              <p className="value">{currentOrganization?.followerCount}</p>
            </div>
            <div className="following-container">
              <p className="label">Following</p>
              <p className="value">{currentOrganization?.followingCount}</p>
            </div>
          </div>
        </div>
        <div className="bottom-row">
          <OrganizationProfilePicture>
            <ProfileAvatar
              style={{ width: "100%", height: "100%" }}
              src={currentOrganization?.image?.url}
            />
          </OrganizationProfilePicture>
          <div className="bottom-row-column">
            {currentOrganization?.orgFields?.careModelCertified && (
              <div className="care-container">
                <OrganizationCarePictureContainer>
                  <img src={careCertifiedImage} />
                </OrganizationCarePictureContainer>
                <span className="care-certified-text">
                  Care model certified
                </span>
              </div>
            )}
            <div className="button-container">
              <div style={{ marginRight: "8px" }}>
                <SecondaryButton
                  onClick={() => setShareQrCodeModalVisible(true)}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    {currentOrganization?.id && (
                      <div
                        style={{
                          height: "auto",
                          margin: "0 auto",
                          maxWidth: 25,
                          width: "100%",
                          marginRight: "8px",
                        }}
                      >
                        <QRCodeIcon />
                      </div>
                    )}
                    <span style={{ display: "block" }}>Share</span>
                  </div>
                </SecondaryButton>
              </div>
              {isCurrentUserAdmin && (
                <SecondaryButton
                  onClick={() =>
                    navigate(`/organizations/${currentOrganization?.id}/edit`)
                  }
                >
                  Edit organization
                </SecondaryButton>
              )}
              
              {isCurrentUserAdmin && isAuthenticated && (
                <FollowButton
                  userId={currentOrganization?.id}
                  accountType={"ORG"}
                />
              )}
              
            </div>
          </div>
        </div>
      </OrganizationProfileTopCard>
      <div className="square-cards-container">
        <OrganizationSparks isEditable={isCurrentUserAdmin}>
          {isCurrentUserAdmin && (
            <div className="sparks-pencil-container">
              <div>
                <button onClick={() => setEditSparkModalVisible(true)}>
                  <PencilWithoutSquare />
                </button>
              </div>
            </div>
          )}
          <div className="icons">
            {currentOrganization?.sparks
              ?.map((spark, id) => (
                <div key={id} className="icon-container">
                  <img src={getSpark(spark)?.logo} />
                </div>
              ))
              .sort()}
          </div>
        </OrganizationSparks>
        {currentOrganization?.orgFields?.antiRacist && (
          <SquareCard className="anti-racist-statement">
            {isCurrentUserAdmin && (
              <div className="pencil-container">
                <button onClick={() => setShowAntiRacistModal(true)}>
                  <PencilWithoutSquare />
                </button>
              </div>
            )}
            <div className="anti-racist-statement-text">
              {currentOrganization?.orgFields?.antiRacist}
            </div>
          </SquareCard>
        )}
      </div>
      <div className="tabs-container">
        <Tabs defaultSelectedTab={"Home"} tabs={tabs} />
      </div>
      {editSparkModalVisible && currentOrganization && (
        <OrganizationEditModal
          isOpen={editSparkModalVisible}
          onClose={() => setEditSparkModalVisible(false)}
          organization={currentOrganization}
          type={currentOrganization.type}
          step="SPARK"
        />
      )}
      {shareQrCodeModalVisible && currentOrganization && (
        <ShareQRCodeModal
          organization={currentOrganization}
          isOpen={shareQrCodeModalVisible}
          onClose={() => setShareQrCodeModalVisible(false)}
        />
      )}
      {showAntiRacistModal && currentOrganization && (
        <AntiRacistStatementModal
          isOpen={showAntiRacistModal}
          onClose={() => setShowAntiRacistModal(false)}
        />
      )}
    </OrganizationProfileWrapper>
  </PageWrapper>
  );
};

export default OrganizationProfile;
